.App {
  text-align: center;
}

.nav-bar-profile-image {
  border-radius: 100px;
}

.family-section-profile-image {
  border-radius: 100px;
  width: 60px;
  height: 60px;

}

.login-page-family-name {
  font-size:4vi;
  font-family: "Times New Roman", Times, serif;
  color: #1B334E;
}

.solomon-page-titles {
  font-family: "Times New Roman", Times, serif;
}

.solomon-page-detail {
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight:100;
}

.solomon-family-name{
  font-size: 4vh;
  font-family: "Times New Roman", Times, serif;
  color: #1B334E;
}

.solomon-light-blue {
  color: #ffffff; 
  background-color: #6D8BC6;
}

.solomon-mid-blue {
  color: #ffffff; 
  background-color: #3C6595;
}

.solomon-high-blue {
  color: #ffffff;
  background-color: #6DA6C6;
}

.solomon-dark-blue {
  color: #ffffff; 
  background-color: #1B334E;
}

.solomon-button {
  padding-left: 2%;
  padding-right: 2%;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif
}

.solomon-pill {
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  border-radius: 20px;
  border-color: transparent;
  font-size: 80%;
  margin-left: 2%;
  margin-right: 2%;
  padding-left: 5%;
  padding-right: 5%;
}

.textbox {
  background-color: #f3f3f3;
  border-color:rgb(236, 236, 236);
}

.solomon-component-box {
  background-color: #f8f8f1;
  border-color: #d1d1d1;
  border-radius: 24px;
  border-width: 1px;
  border-style: solid;
}

.solomon-sub-component {
  background-color: white;
  border-color: #d1d1d1;
  border-radius: 24px;
  border-width: 1px;
  border-style: solid;
}

.medium-component {
  height: 9%;
}